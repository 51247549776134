import React, {useEffect, Fragment, useState} from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import ReactDOM from 'react-dom'

import MenuHeader from 'components/Header/Header'
import SidebarMenu from 'components/Sidebar/SidebarMenu'
import { setLanguage } from 'store/actions/app'

const PrivateSuperAdminRoute = (props) => {
  const {
    component: Component,
    isSuperAdminAuthenticated,
    lang,
    ...rest
  } = props
  const [expand, setExpand] = useState(0)
  const [previousExpand, setPreviousExpand] = useState(null)
  const [mobileOnly, setMobileOnly] = useState(false)

  useEffect(()=>{
		if(props.location.search?.includes('lang')){
			props.setLanguage(
				props.location.search.slice(
					props.location.search.indexOf('lang=')+5, 
					props.location.search.indexOf('lang=')+7
				)
			)
		}
	},[])

  return (
    <Route
      {...rest}
      render={(props) =>
        isSuperAdminAuthenticated ? (
          <Fragment>
            <MenuHeader 
              rest={rest}
              logout={()=>{}}
              history={props.history}
              setExpand={setExpand}
              expand={expand}
              setPreviousExpand={setPreviousExpand}
              previousExpand={previousExpand}
              openModal={false}
              setOpenModal={()=>{}}
              mobileOnly={mobileOnly}
              setMobileOnly={setMobileOnly}
            />
            <div
								className='main-content'
								style={
									expand == null || expand == 2
										? { marginLeft: '250px' }
										: expand == 1
										? { marginLeft: '55px' }
										: null
								}
							>
              {ReactDOM.createPortal(
									<SidebarMenu
										history={props.history}
										location={props.location}
										user={{
                      modules: [
                        {
                          permisitions: {ACCESS: true},
                          module_group: 'Compañías',
                          isVisible: true,
                          name: 'Administrador de Clientes',
                          url: 'sys_company_administrator',
                          icon: 'archive',
                        }
                      ],
                    }}
										logout={rest?.logout}
										rest={rest}
										expand={expand}
										setExpand={setExpand}
										previousExpand={previousExpand}
										setPreviousExpand={setPreviousExpand}
										openModal={false}
										setOpenModal={()=>{}}
										mobileOnly={mobileOnly}
										setMobileOnly={setMobileOnly}
									/>,
									document.getElementById('root')
								)}
              <Component {...props} />
            </div>
          </Fragment>
        ) : (
          <Redirect to={{
            pathname: '/login', 
            search: `?lang=${lang}`}}
          />
        )
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
	setLanguage: (lang) => dispatch(setLanguage(lang))
})

const mapStateToProps = (state) => ({
  isSuperAdminAuthenticated: state.app?.superAdmin,
  lang: state.app.lang ?? 'en',
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateSuperAdminRoute);
