const initialState = {
  isQrRead: false,
  unableActionsRelatedToCompanies: false,
  relation: null,
  alreadyRedirect: false,

  //Table data
  tableId: null,
  tableName: null,
  table: null,

  //Company data
  sysCompanyId: null,
  foodStallId: null,
  companyName: null,
  currency: "MXN",
  attentionType: null,
  typeOfClientAccess: null,
  clientPermissions: null,
  logoUrl: null,
  advertisingImage: null,
  generalParameters: {},
  company: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_IS_QR_READ": {
      return {
        ...state,
        isQrRead: true,
      }
    }
    case "SET_TABLE_CODE_DATA": {
      return {
        ...state,
        tableId: payload.table_id,
        sysCompanyId: payload.sys_company_id,
      }
    }
    case 'LOAD_COMAPANY_INFO': {
      return {
        ...state,
        company: payload,
        sysCompanyId: payload.id,
        companyName: payload?.name,
        currency: payload?.currency,
        attentionType: payload?.attention_type,
        typeOfClientAccess: payload?.type_of_client_access,
        logoUrl: payload?.ExtraData?.logo_url,
        advertisingImage: payload?.ExtraData?.advertising_image,
        clientPermissions: payload?.clientPermissions,
        generalParameters: payload?.GeneralParameters
      }
   }
    case 'TABLE_INFO': {
      return {
        ...state,
        tableName: payload.name,
        table: payload,
      }
    }
    case 'SET_UNREAD_QR': {
      return {
        initialState
      }
    }
    case 'LOGOUT': {
      return initialState
    }
    default: {
      return state;
    }
  }
};
