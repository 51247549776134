import crudHandlers from './_crud'

const initialState = {
	announcementsById: null,
	entities: {},
	is_fetching: false,
	active: undefined
}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOAD_ANNOUNCEMENTS_BY_ID':
			return { ...state, announcementsById: payload }
		default:
			state = crudHandlers(state, action, 'announcement')

			return state
	}
}
