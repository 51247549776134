export default function getUrlParams(url) {
    const initialIndex = url.includes('?') ? url.indexOf('?')+1 : url.indexOf('&')+1
    const queryString = url.slice(initialIndex)
    const arr = queryString.split('&')
    const objQuery = arr.reduce((a, e)=>{
        const [key, value] = e.split('=')
        a[key] = value
        return a
    }, {})
    return objQuery
}