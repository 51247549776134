import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { setLanguage } from 'store/actions/app'

const PublicRoute = (props) => {
  const {
    isAuthenticated,
    isSuperAdminAuthenticated,
    lang,
    component: Component,
    user,
    ...rest
  } = props
  
  useEffect(()=>{
		if(props.location.search?.includes('lang')){
			props.setLanguage(
				props.location.search.slice(
					props.location.search.indexOf('lang=')+5, 
					props.location.search.indexOf('lang=')+7
				)
			)
		}
	},[props.location])

  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          <Redirect to={{
            pathname: user?.redirect_url ?? user.role?.redirect_url ?? '/', 
            search: `?lang=${lang}`}}
          />
        ) : (
          isSuperAdminAuthenticated
        ) ? (
          <Redirect to={{
            pathname: '/sys_company_administrator', 
            search: `?lang=${lang}`}}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
	setLanguage: (lang) => dispatch(setLanguage(lang))
})

const mapStateToProps = (state) => ({
  user: state.app.user,
  isAuthenticated: !!state.app.user,
  isSuperAdminAuthenticated: !!state.app?.superAdmin,
  lang: state.app.lang ?? 'en',
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
