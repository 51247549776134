import crudHandlers from './_crud'

const initialState = {
	entitiesArray: [],
    waitersById: null,
	// personalById: null,
}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOAD_ASSISTANTS':
			return { ...state, entitiesArray: payload }
		default:
			// state = crudHandlers(state, action, 'waiters')

			return state
	}
}
