import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PublicClientRoute = ({
  isClientAuthenticated,
  component: Component,
  client,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isClientAuthenticated ? <Redirect to='/client/company_qr_scanning' /> : <Component {...props} />
      }
    />
  );
};

const mapStateToProps = (state) => ({
  client: state.app.client,
  isClientAuthenticated: !!state.app?.client,
});

export default connect(mapStateToProps)(PublicClientRoute);
