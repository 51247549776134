import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { logout, setLanguage } from 'store/actions/app'
import queryString from 'query-string'
import moment from 'moment'

import { callApi } from '../../utils/api'
import translations from '../../utils/translations'
import './Header.scss'

import {
	Message,
	Button,
	Menu,
	Icon,
	Popup,
	Image,
	Grid,
	Label,
	Header,
	Segment,
	Dropdown,
} from 'semantic-ui-react'

const getAvatar = (user, superAdmin) => {
	let name = 'Guest'
	if (superAdmin) {
		name = superAdmin?.name
	}
	if (user) {
		name = user?.name
	}
	return `https://ui-avatars.com/api/?name=${name}`
}

const HeaderComponent = (props) => {
	const {
		rest,
		user,
		client,
		logout,
		history,
		expirations,
		employees,
		setExpand,
		openModal,
		setOpenModal,
		mobileOnly,
		previousExpand,
		setPreviousExpand,
		lang,
		currentTranslations,
		isAuthenticated,
	} = props

	const [modalVisible, setModalVisible] = useState(false)
	const [employes, setEmployees] = useState([])
	const [hasEmployeeRights, setHasEmployeeRights] = useState(false)
	const [canCheckLicense, setCanCheckLicense] = useState(false)
	const [hasDocumentsRights, setHasDocumentsRights] = useState(false)
	const [notificationsCount, setNotificationsCount] = useState(0)
	const [listServiceOrders, setListServiceOrders] = useState([])
	const [pendingOrders, setPendingOrders] = useState([])
	const [delayedOrders, setDelayedOrders] = useState([])
	const [waitingOrders, setWaitingOrders] = useState([])
	const componentDidMount = async () => {
		//const startOfMonth = moment().subtract(45, 'days').format('YYYY-MM-DD');
		const endOfMonth = moment().add(45, 'days').format('YYYY-MM-DD')
		let res = await callApi(
			`/employee?license_expiration_date.lte=${endOfMonth}&active.eq=1`,
			{
				method: 'GET',
				headers: {
					Authorization: user.token,
				},
			}
		)
		setEmployees(res.body)

		let list = await props.loadAndUpdate()
		setListServiceOrders(list)
	}

	useEffect(() => {
		setPendingOrders(listServiceOrders.filter((o) => o.status === 'PENDIENTE'))
		setDelayedOrders(
			listServiceOrders.filter((order) => order.status === 'ATRASADA')
		)
		setWaitingOrders(
			listServiceOrders.filter((order) => order.status === 'EN ESPERA')
		)
	}, [listServiceOrders])

	useEffect(() => {
		hasDocumentsRights && props?.loadExpirationReport && props?.loadExpirationReport()
	}, [hasDocumentsRights])

	useEffect(() => {
		setHasDocumentsRights(user?.modules?.findIndex((m) => m.id === 102) > -1)
	}, [props.user])

	useEffect(() => {
		let count = employes?.length ?? 0
		count += expirations?.Documents?.length ?? 0
		count += expirations?.TrafficTickets?.length ?? 0
		count += expirations?.FastCards?.length ?? 0
		count += pendingOrders?.length ?? 0
		count += delayedOrders?.length ?? 0
		count += waitingOrders?.length ?? 0
		setNotificationsCount(count)
	}, [employes, expirations, pendingOrders, delayedOrders, waitingOrders])

	useEffect(() => {
		let employeePermissions = user?.modules?.find((m) => m.url === 'employees')?.permisitions
		if (employeePermissions?.ACCESS) {
			componentDidMount()
		}
		setCanCheckLicense(employeePermissions)
	}, [employees])

	let userName = props?.user?.username || props?.superAdmin?.username || props?.client?.email

	return (
		<Menu
			className='NavBar__Header'
			inverted
			borderless
			size='tiny'
			attached='top'
			fixed='top'
		>
			<Menu.Item className='NavBar__Item'>
				<Icon
					className='NavBar__Outline'
					name='list'
					size='large'
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setExpand((previousExpandedState) => {
							setPreviousExpand(previousExpandedState)
							if (!mobileOnly)
								return previousExpandedState == 1
									? previousExpand == null || previousExpand == 2
										? 0
										: 2
									: 1
							return !previousExpandedState ? 1 : 0
						})
					}}
				/>
			</Menu.Item>
			<Menu className='SubMenu__Header' size='mini'>
				<Menu.Item
					onClick={() => {
						history.push(user ? '/' : client ? '/client/company_qr_scanning' : '/')
					}}
				>
					<Header className='NavBar__Item'>
						<h4 className='NavBar__Outline'>Ruf</h4>
						<Header.Subheader className='NavBar__Outline'>
							V 0.1.27
						</Header.Subheader>
					</Header>
				</Menu.Item>
				<Menu.Item fitted position='right'>
					<Icon
						className='Circle_Icon NavBar__Item'
						name='question'
						circular
						onClick={() => {
							setOpenModal(!openModal)
						}}
					/>
				</Menu.Item>
			</Menu>
			<Menu.Menu position={mobileOnly ? null : 'right'} borderless size='tiny'>
				<Menu.Item position='right' fitted className='NavBar__Item'>
					{(canCheckLicense || hasDocumentsRights) && (
						<Popup
							pinned
							trigger={
								<Menu.Item style={{ marginRight: '1vw', padding: '0 0.8em' }}>
									<Icon
										className='NavBar__Outline'
										size='big'
										name='bell'
										fitted
									>
										{notificationsCount > 0 && (
											<Label
												color='red'
												floating
												circular
												style={{
													fontFamily:
														"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
													fontSize: '0.45em',
												}}
											>
												{notificationsCount}
											</Label>
										)}
									</Icon>
								</Menu.Item>
							}
							content={
								<div>
									{notificationsCount == 0 && (
										<Message
											size='tiny'
											warning
											icon='info'
											header={`No hay notificaciones`}
										/>
									)}
									{employes.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/employees',
													search: queryString.stringify({
														filter: employes.map((e) => e.id).join(','),
													}),
												})
											}}
											warning
											icon='warning'
											header={`${employes.length} licencia${
												employes.length > 1 ? 's' : ''
											} vence${
												employes.length > 1 ? 'n' : ''
											} en menos de 45 días`}
											content='Click aquí para ver detalles'
										/>
									)}

									{expirations.Documents?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/documents_expirations',
													search: queryString.stringify({
														tabId: 0,
													}),
												})
											}}
											warning
											icon='warning'
											header={`${expirations.Documents?.length} documento${
												expirations.Documents?.length > 1 ? 's' : ''
											} por vencer`}
											content='Click aquí para ver detalles'
										/>
									)}

									{expirations.TrafficTickets?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/documents_expirations',
													search: queryString.stringify({
														tabId: 1,
													}),
												})
											}}
											warning
											icon='warning'
											header={`${expirations.TrafficTickets?.length} multa${
												expirations.TrafficTickets?.length > 1 ? 's' : ''
											} por vencer`}
											content='Click aquí para ver detalles'
										/>
									)}

									{expirations.FastCards?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/documents_expirations',
													search: queryString.stringify({
														tabId: 2,
													}),
												})
											}}
											warning
											icon='warning'
											header={`${expirations.FastCards?.length} Tarjeta${
												expirations.FastCards?.length > 1 ? 's' : ''
											} Fast por vencer`}
											content='Click aquí para ver detalles'
										/>
									)}
									{waitingOrders?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/workshop/service_order',
													search: queryString.stringify({
														filter: waitingOrders.map((e) => e.id).join(','),
													}),
												})
											}}
											warning
											icon='warning'
											header={`${waitingOrders?.length} Orden${
												waitingOrders?.length > 1 ? 'es' : ''
											} en estatus EN ESPERA`}
											content='Click aquí para ver detalles'
										/>
									)}
									{pendingOrders?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/workshop/service_order',
													search: queryString.stringify({
														filter: pendingOrders.map((e) => e.id).join(','),
													}),
												})
											}}
											color='orange'
											icon='warning'
											header={`${pendingOrders?.length} Orden${
												pendingOrders?.length > 1 ? 'es' : ''
											} en estatus PENDIENTE${
												pendingOrders?.length > 1 ? 'S' : ''
											}`}
											content='Click aquí para ver detalles'
										/>
									)}
									{delayedOrders?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/workshop/service_order',
													search: queryString.stringify({
														filter: delayedOrders.map((e) => e.id).join(','),
													}),
												})
											}}
											color='red'
											icon='warning'
											header={`${delayedOrders?.length} Orden${
												delayedOrders?.length > 1 ? 'es' : ''
											} en estatus ATRASADA${
												delayedOrders?.length > 1 ? 'S' : ''
											}`}
											content='Click aquí para ver detalles'
										/>
									)}
								</div>
							}
							position='bottom right'
							on='click'
						/>
					)}
				</Menu.Item>
				<Menu.Item fitted position='right' className='NavBar__Item'>
					<Popup
						onOpen={() => setModalVisible(true)}
						onClose={() => setModalVisible(false)}
						pinned
						on='click'
						trigger={
							<Menu.Item className='NavBar__Item'>
								<Image src={getAvatar(user, props.superAdmin)} avatar />
							</Menu.Item>
						}
						content={
							<Segment className='NavBar__Outline'>
								<Grid
									divided='vertically'
									columns='equal'
									className='Header_PopUp'
									style={{ display: 'inline-block', padding: 0 }}
								>
									<Grid.Row columns={1}>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Image
												className='Header_PopUp_Image'
												src={getAvatar(user, props.superAdmin)}
												avatar
											/>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Dropdown
												options={[{ key: 'en', value: 'en', text: 'English' }, 
												{ key: 'es', value: 'es', text: 'Español' },]}
												placeholder='Seleccione un idioma.'
												style={{color: 'black'}}
												value={lang}
												onChange={(e, {value})=>{
													props.setLanguage(value)
												}}
											/>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<h4 className='NavBar__InvertedOutline'>
												{userName?.toUpperCase()}
											</h4>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Button
												className='Header_PopUp_Button'
												basic
												icon
												labelPosition='right'
												onClick={() => {
													if(isAuthenticated){
														logout()
														props.history.push('/login')
													}else{
														props.history.push('/login_client')
													}
												}}
											>
												<Icon name='sign out' />

												{isAuthenticated ? currentTranslations.logOut : currentTranslations.logIn}
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						}
						position='bottom right'
					/>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	)
}

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
	setLanguage: (lang) => dispatch(setLanguage(lang)),
})

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.user || !!state.app.client || !!state.app.superAdmin,
	user: state.app?.user,
	superAdmin: state.app?.superAdmin,
	client: state.app.client,
	employees: state.employees,
	expirations: state.document_items?.expiration_report ?? [],
	lang: state.app.lang ?? 'en',
	currentTranslations: translations[state.app.lang],
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
