import crudHandlers from './_crud'

const initialState = {
	offers: [],
	offersById: null,
	entities: {},
	is_fetching: false,
	active: undefined
}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOAD_OFFERS_BY_ID':
			return { ...state, offerslById: payload }
		default:
			state = crudHandlers(state, action, 'offer')

			return state
	}
}
