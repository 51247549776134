import React, { useEffect, useState } from 'react'
import {
    Button,
    Image,
    Modal,
    ModalContent,
    ModalDescription,
} from 'semantic-ui-react'
import AppStoreImg from './images/appstore_img.png'
import PlayStoreImg from './images/playstore_img.png'
import LogoImge from './images/logo1024.jpg'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const DownloadAppNotification = () => {
    const [open, setOpen] = useState(false)
    const history = useHistory()

    const userAgent = navigator.userAgent
    const isMobile = (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('Android')) ?? false
    
    const androidURL = 'https://play.google.com/store/apps/details?id=mx.restec&pcampaignid=web_share'
    const iosURL = 'https://apps.apple.com/mx/app/restec-descubre-y-saborea/id6463335518'

    let appURL

    if(userAgent.includes('Android'))
        appURL = androidURL
    if(userAgent.includes('iPhone') || userAgent.includes('iPad'))
        appURL = iosURL

    const dwnButtonText = isMobile ? 'Descargar App' : 'Registrarse'

    useEffect(() => {
        const timer = setTimeout(() => {
            if(userAgent.includes('Android') || userAgent.includes('iPhone') || userAgent.includes('iPad')){
                setOpen(true)
            }
        }, 120000)

        return () => clearTimeout(timer)
    }, [])

    const handleAppDownload = () => {
        if (isMobile) {
            window.open(appURL, '_blank')
        } else {
            history.push('/account_register?type=cliente')
        }
    }

    const handleContinueClick = () => {
        setOpen(false)
    }

    return (
        <>
            <Modal open={open} size='small'>
                <ModalContent style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Image src={LogoImge} />
                    {!isMobile && (<ModalDescription>
                        ¿Desea registrarse en nuestra plataforma para una mejor experiencia?
                    </ModalDescription>)}
                    {isMobile && (<ModalDescription>
                        ¿Desea descargar nuestra aplicación para una mejor experiencia?
                    </ModalDescription>)}
                    <p></p>
                    <Button style={{ margin: '0 auto' }} className='general-color' onClick={handleAppDownload}>{dwnButtonText}</Button>
                    {/* {userAgent.includes('Android') && (<a href='https://play.google.com/store/apps/details?id=mx.restec&pcampaignid=web_share'>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', margin: 10 }}>
                            <Image src={PlayStoreImg} style={{ width: '30%', height: 'auto', }} />
                        </div>
                    </a>)}
                    {(userAgent.includes('iPhone') || userAgent.includes('iPad')) && (<a href='https://apps.apple.com/mx/app/restec-descubre-y-saborea/id6463335518'>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', margin: 10 }}>
                            <Image src={AppStoreImg} style={{ width: '30%', height: 'auto' }} />
                        </div>
                    </a>)} */}
                    <Button style={{ margin: '0 auto' }} className='general-color' onClick={handleContinueClick}>Continuar navegando</Button>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DownloadAppNotification