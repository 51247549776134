import crudHandlers from './_crud'

const initialState = {
  entities: {},
  is_fetching: false,
  active: undefined,
  currentDayOrders: [],
  currentWeekOrders: [],
  monthOrders: [],
  yearOrders: [],
  scanOrdersWeb: [],
  scanOrdersMobile: [],
  pickupOrdersWeb: [],
  pickupOrdersMobile: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Casos específicos para cargar datos de órdenes por tipo y período
    case 'SET_CURRENT_DAY_ORDERS':
      return {
        ...state,
        currentDayOrders: payload,
        loading: false,
        error: null,
      };
    case 'SET_CURRENT_WEEK_ORDERS':
      return {
        ...state,
        currentWeekOrders: payload,
        loading: false,
        error: null,
      };
    case 'SET_MONTHLY_ORDERS':
      return {
        ...state,
        monthOrders: payload,
        loading: false,
        error: null,
      };
    case 'SET_YEARLY_ORDERS':
      return {
        ...state,
        yearOrders: payload,
        loading: false,
        error: null,
      };
    case 'SET_SCAN_ORDERS_WEB':
      return {
        ...state,
        scanOrdersWeb: payload,
        loading: false,
        error: null,
      };
    case 'SET_SCAN_ORDERS_MOBILE':
      return {
        ...state,
        scanOrdersMobile: payload,
        loading: false,
        error: null,
      };
    case 'SET_PICKUP_ORDERS_WEB':
      return {
        ...state,
        pickupOrdersWeb: payload,
        loading: false,
        error: null,
      };
    case 'SET_PICKUP_ORDERS_MOBILE':
      return {
        ...state,
        pickupOrdersMobile: payload,
        loading: false,
        error: null,
      };

    default:
      // Para otras acciones CRUD, utilizar el manejador genérico
      return crudHandlers(state, action, 'client_order');
  }
};
