const initialState = {
	lang: 'es',
	recoveryEmail: '',
}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOGIN': {
			return {
				...state,
				user: { ...payload },
			}
		}
		case 'LOGOUT': {
			return {
				...state,
				user: null,
				superAdmin: null,
				client: null,
			}
		}
		case 'LOGIN_CLIENT': {
			return {
				...state,
				client: { ...payload.client },
			}
		}
		case 'CLIENT_LOGOUT': {
			return {
				...state,
				client: null,
			}
		}
		case 'SUPER_ADMIN_LOGIN': {
			return {
				...state,
				superAdmin: payload,
			}
		}
		case 'SET_LANGUAGE': {
			return {
				...state, 
				lang: payload
			}
		}
		case 'SET_RECOVERY_EMAIL': {
			return {
				...state,
				recoveryEmail: payload,
			}
		}
		default:
			return state
	}
}
